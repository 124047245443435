import React, { useCallback, useEffect, useState } from 'react';
import url from 'url';
import _ from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import TaxFlowConfirmModal from '@app/src/Components/TaxFlow/Common/TaxFlowConfirmModal';
import TaxFlowPill from '@app/src/Components/TaxFlow/Common/TaxFlowPill';
import {
  useGetReviewPillsQuery,
  useGetHasTaskAssigneeQuery,
  useGetUIStageQuery,
  useUnlockUserMutation,
  useGetCreditSuggestionPillsQuery
} from '@app/src/api/taxDataApi';
import MagicStar from '@app/src/assets/magic-star.svg?react';
import { TAXFLOW_PREMIUM_BLOCKING_MODAL_TYPES } from '@app/src/constants/constants';
import { isMobileBrowser, isReactNative } from '@app/src/global/Helpers';
import { useIsPremiumSubscriber } from '@app/src/hooks/pricingHooks';
import { useShowPremiumBlockingModal } from '@app/src/hooks/useShowPremiumBlockingModal';
import { useSubstitutedText } from '@app/src/hooks/useSubstitutedText';
import { useUnlockedViaImpersonation } from '@app/src/hooks/useUnlockedViaImpersonation';
import history from '@app/src/keeperHistory';
import { selfUnlockModalOpenSelector } from '@app/src/selectors/navigationListSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import TaxFlowCollectionDeleteModal from '@app/src/taxflow/collection/components/TaxFlowCollectionDeleteModal';
import TaxFlowSuggestionModal from '@app/src/taxflow/collection/components/TaxFlowSuggestionModal';
import { deleteCollection } from '@app/src/taxflow/collection/services/collectionService';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { getPathComponentBySlug } from '@app/src/taxflow/main/utils/mainUtils';
import { setBlockingModalProps, setSelfUnlockModalOpen } from '@app/src/taxflow/navigation/actions/navigationActions';
import { PATH_COMPONENT__TAX_HOME } from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  PATH_COMPONENT__EXPENSES,
  TAXFLOW_BASE_URL,
  UI_STAGE__CONFIRM_AMOUNTS,
  UI_STAGE_METADATA
} from '@app/src/taxflow/shared/constants/sharedConstants';
import { currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';
import '@app/src/Components/TaxFlow/Question/TaxFlowReviewPillsSection.scss';

const TaxFlowReviewPillsSection = ({
  currentQuestion,
  selfUnlockModalOpen,
  setSelfUnlockModalOpen,
  setLoading = _.noop,
  unfurlBulkUpload = _.noop
}) => {
  const dispatch = useDispatch();
  const year = useSelector(yearSelector);
  const [unlockUser] = useUnlockUserMutation();

  const { data: hasTaskAssignee, isLoading: hasTaskAssigneeLoading } = useGetHasTaskAssigneeQuery({ year });
  const { data: reviewPills, isLoading: reviewPillsLoading } = useGetReviewPillsQuery({ year });
  const { data: uiStage, isLoading: uiStageLoading } = useGetUIStageQuery({ year });
  const { data: isPremiumSubscriber, isLoading: isPremiumSubscriberLoading } = useIsPremiumSubscriber();
  const { data: creditSuggestionPills, isLoading: creditSuggestionPillsLoading } = useGetCreditSuggestionPillsQuery({
    year
  });
  const showPremiumBlockingModal = useShowPremiumBlockingModal();

  const [showCollectionDeleteModal, setShowCollectionDeleteModal] = useState(false);
  const [showSuggestionModal, setSuggestionModalShow] = useState(false);
  const [suggestionModalProps, setSuggestionModalProps] = useState({});
  const [collectionDeleteModalTarget, setCollectionDeleteModalTarget] = useState({
    collectionType: undefined,
    collectionId: undefined
  });

  const isLoading =
    reviewPillsLoading ||
    uiStageLoading ||
    isPremiumSubscriberLoading ||
    hasTaskAssigneeLoading ||
    creditSuggestionPillsLoading;

  const pillSectionsLHS = _.take(reviewPills, _.size(reviewPills) / 2);
  const pillSectionsRHS = _.takeRight(reviewPills, _.size(reviewPills) / 2);

  const isUserUnlockedViaImpersonation = useUnlockedViaImpersonation();

  const editEnabled =
    _.isNil(uiStage) ||
    _.isEmpty(uiStage) ||
    _.find(UI_STAGE_METADATA, { stage: uiStage })?.editEnabled ||
    isUserUnlockedViaImpersonation;

  const selfUnlockEnabled = uiStage === UI_STAGE__CONFIRM_AMOUNTS && !(isPremiumSubscriber && hasTaskAssignee);

  useEffect(() => setLoading(isLoading), [isLoading, setLoading]);

  const navigateToLinkedQuestion = useCallback(
    (collectionTypeItem) => {
      const {
        startSlug,
        collectionType,
        collectionId,
        nextQuestionCollectionId,
        text,
        isPremium,
        premiumCriteriaText,
        blockingModal
      } = collectionTypeItem;
      trackActivity('review: pill clicked', { collectionType, collectionId, pillName: text });
      if (!editEnabled) {
        return;
      }
      if (collectionType === 'deductions') {
        !isReactNative() &&
          !isMobileBrowser() &&
          history.push(
            url.format({
              pathname: `/${PATH_COMPONENT__EXPENSES}`
            })
          );
        return;
      } else if (isPremium) {
        const onSubmit = () => {
          dispatch(
            deleteCollection({
              collectionType,
              collectionId
            })
          );
          dispatch(
            setBlockingModalProps({
              ...blockingModal,
              open: false
            })
          );
        };
        showPremiumBlockingModal({
          formName: text,
          premiumCriteriaText,
          type: TAXFLOW_PREMIUM_BLOCKING_MODAL_TYPES.DELETE,
          onSubmit,
          submitButtonText: 'Delete',
          collectionTypeItem
        });
        return;
      }
      const nextPathComponent = getPathComponentBySlug(startSlug);
      const nextCollectionId = nextQuestionCollectionId || collectionId;
      if (_.isNil(nextPathComponent)) {
        defaultCaptureMessage('Could not advance to review question', {
          coll_type: collectionType,
          coll_id: nextCollectionId,
          slug: startSlug
        });
        return;
      }
      history.push(
        url.format({
          pathname: `/${TAXFLOW_BASE_URL}/${nextPathComponent}`,
          query: {
            collectionId: nextCollectionId === '0' ? undefined : nextCollectionId
          }
        })
      );
    },
    [editEnabled, showPremiumBlockingModal, dispatch]
  );

  const confirmPillDeletion = useCallback(
    ({ collectionType, collectionId, text }) => {
      trackActivity('review: pill removal requested', { collectionType, collectionId, pillName: text });
      if (!editEnabled) {
        return;
      }
      setCollectionDeleteModalTarget({
        collectionType: collectionType,
        collectionId
      });
      setShowCollectionDeleteModal(true);
    },
    [editEnabled]
  );

  const onEditClick = () => {
    if (!selfUnlockModalOpen) {
      trackActivity('self unlock: modal opened', {
        question: currentQuestion?.slug
      });
      setSelfUnlockModalOpen(true);
    }
  };

  const onSuggestionClick = (pill) => {
    setSuggestionModalShow(true);
    setSuggestionModalProps({
      title: pill.text,
      header: pill.suggestionModalHeader,
      suggestionModalText: pill.suggestionModalText,
      linkedSlug: pill.startSlug,
      linkedQuestionCollectionId: pill.collectionId,
      shouldUnfurlBulkUpload: pill.suggestBulkUpload,
      dismissSlug: pill.dismissSlug
    });
    trackActivity('suggestion pill: clicked', { pillName: pill.text });
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      <div className='taxflow-review-pills-section'>
        <div className='taxflow-review-pills-section-pills'>
          <div className='taxflow-review-pills-section-pills-column'>
            {pillSectionsLHS.map(({ section, pills }) => (
              <PillsSubSection
                key={section}
                heading={section}
                pills={pills}
                editEnabled={editEnabled}
                onClickPill={navigateToLinkedQuestion}
                onRemovePill={confirmPillDeletion}
              />
            ))}
          </div>
          <div className='taxflow-review-pills-section-pills-separator'></div>
          <div className='taxflow-review-pills-section-pills-column'>
            {pillSectionsRHS.map(({ section, pills }) => (
              <PillsSubSection
                key={section}
                heading={section}
                pills={pills}
                editEnabled={editEnabled}
                onClickPill={navigateToLinkedQuestion}
                onRemovePill={confirmPillDeletion}
              />
            ))}
          </div>
        </div>
        {!_.isEmpty(creditSuggestionPills) ? (
          <div>
            Suggested for you
            <div className='taxflow-review-pills-section-suggestions'>
              {_.map(creditSuggestionPills, (pill) => (
                <TaxFlowPill text={pill.text} variant='add' key={pill.text} onClick={() => onSuggestionClick(pill)} />
              ))}
            </div>
          </div>
        ) : null}
      </div>
      {selfUnlockEnabled ? (
        <div className='taxflow-review-pills-section-edit' onClick={onEditClick}>
          Unlock to make edits
        </div>
      ) : null}
      {showCollectionDeleteModal && (
        <TaxFlowCollectionDeleteModal
          collectionType={collectionDeleteModalTarget.collectionType}
          collectionId={collectionDeleteModalTarget.collectionId}
          hideModal={() => setShowCollectionDeleteModal(false)}
        />
      )}
      {showSuggestionModal && (
        <TaxFlowSuggestionModal
          {...suggestionModalProps}
          onClose={() => setSuggestionModalShow(false)}
          unfurlBulkUpload={unfurlBulkUpload}
        />
      )}
      <TaxFlowConfirmModal
        open={selfUnlockModalOpen}
        setOpen={setSelfUnlockModalOpen}
        header='Edit your return and re-submit?'
        body="You'll need to re-submit for review after editing your return."
        // non breaking hyphen
        confirmText='Yes, edit and re&#8209;submit'
        onConfirm={async () => {
          trackActivity('self unlock: modal confirmed', {
            question: currentQuestion?.slug
          });
          await unlockUser({ year });
          history.replace(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__TAX_HOME}`);
        }}
      />
    </>
  );
};

const PillsSubSection = ({ heading, pills = [], editEnabled, onClickPill, onRemovePill }) => {
  return (
    <div className='taxflow-review-pills-section-pills-section'>
      <div className='taxflow-review-pills-section-pills-section-heading'>{heading}</div>
      <div className='taxflow-review-pills-section-pills-section-pills'>
        {pills
          .filter((pill) => !pill.addable || editEnabled)
          .map((pill) => {
            const key = pill.text;

            return (
              <ReviewPill
                key={key}
                pill={pill}
                editEnabled={editEnabled}
                onClickPill={onClickPill}
                onRemovePill={onRemovePill}
              ></ReviewPill>
            );
          })}
      </div>
    </div>
  );
};

const ReviewPill = ({ pill, editEnabled, onClickPill, onRemovePill }) => {
  const { substitutedText: pillText, isLoading } = useSubstitutedText({ text: pill.text });

  if (isLoading) {
    return null;
  }

  const deriveVariant = (pill) => {
    if (!editEnabled) {
      return 'disabled';
    }
    if (pill.warning) {
      return 'warning';
    }
    if (pill.addable) {
      return 'add';
    }
    return 'default';
  };

  const deriveIcon = (pill) => {
    if (pill.isPremium) {
      return <MagicStar />;
    }
    return pill.icon;
  };

  return (
    <TaxFlowPill
      text={pillText}
      icon={deriveIcon(pill)}
      removable={editEnabled && pill.removable}
      variant={pill.variant || deriveVariant(pill)}
      onClick={() => onClickPill(pill)}
      onRemove={() => onRemovePill(pill)}
    />
  );
};

const mapStateToProps = (state) => ({
  currentQuestion: currentQuestionSelector(state),
  selfUnlockModalOpen: selfUnlockModalOpenSelector(state)
});

const mapDispatchToProps = {
  setSelfUnlockModalOpen
};

const ConnectedTaxFlowReviewPillsSection = connect(mapStateToProps, mapDispatchToProps)(TaxFlowReviewPillsSection);

export default ConnectedTaxFlowReviewPillsSection;
